import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Learnings and tools
// UX Research, Figma, React, API proxying
import { ReactComponent as UXIcon } from './svg/ux.svg';
import { ReactComponent as FigmaIcon } from './svg/figma.svg';
import { ReactComponent as ReactIcon } from './svg/react.svg';
import { ReactComponent as APIIcon } from './svg/api.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
import DemoLink from '@common/DemoLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './tender-search.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/tender-search/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/tender-search/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>
              <Paragraph>{description}</Paragraph>
              <Paragraph>
                <DemoLink href="https://ted.aitorperez.com/">
                  Try the prototype
                </DemoLink>
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="Searcher redesigned"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <UXIcon />
                  <LearningTopic>UX research</LearningTopic>
                </Learning>
                <Learning>
                  <FigmaIcon />
                  <LearningTopic>Figma</LearningTopic>
                </Learning>
                <Learning>
                  <ReactIcon />
                  <LearningTopic>React</LearningTopic>
                </Learning>
                <Learning>
                  <APIIcon />
                  <LearningTopic>API proxying</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Title>Understanding the context</Title>
              <Paragraph>
                TED (Tenders Electronic Daily) is the online version of the
                Supplement to the Official Journal of the EU, dedicated to
                European public procurement.
              </Paragraph>
              <Paragraph>
                TED publishes 746 thousand procurement award notices a year,
                including 235 thousand calls for tenders which are worth
                approximately €545 billion.
              </Paragraph>
              <Paragraph>
                As a daily user of TED following relevant tenders for a business
                unit for over 3 years, I produced the following insights:
              </Paragraph>

              <List>
                <Item>Screen is heavily underused</Item>
                <Item>
                  Search results only use a minor fraction (12%) of the content
                  on page load and is positioned almost on the page fold (like
                  secondary content)
                </Item>
                <Item>
                  There are multiple places to access the same search refinement
                  options
                </Item>
                <Item>
                  Search refinement navigates to a different page where you lose
                  context of the search results and end up doing trial-and-error
                  searches
                </Item>
                <Item>
                  Results table fails to show very relevant data (buyer name,
                  contract object, contract value) and instead chooses to
                  display low value data (like country, even when the country is
                  a basic filtered value), making it necessary to open and
                  inspect each publication notice to determine what is it about
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="review"
                overflow
                alt="Review of the current design"
                description="Insights from a frequent user of the searcher"
              />

              <Title>Specifying user requirements</Title>
              <Paragraph>
                To understand what criteria is really useful to single out
                relevant call for tenders, a simple one-question survey was
                conducted with a group of account managers:
              </Paragraph>

              <List>
                <Item>
                  The surveyees were asked to choose what fields they would need
                  to identify an interesting procurement notice
                </Item>
                <Item>Responses allowed between 1 and 5 fields</Item>
                <Item>The options were all the fields available at TED</Item>
                <Item>The list was randomized</Item>
              </List>

              <Paragraph>
                Contract subject, contract value and contract buyer, followed by
                proposal submission deadline and links to both the tender
                documents and the procurement website.
              </Paragraph>
              <Paragraph>
                Not every surveyee used the complete allowance of fields (20%
                were satisfied with only 4 fields).
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="survey"
                overflow
                alt="Survey report"
                description="Results from the user research"
              />

              <Paragraph>
                In summary, concrete requirements of the redesign are:
              </Paragraph>
              <List>
                <Item>Reduce unused screen space</Item>
                <Item>Place search results front and center</Item>
                <Item>Display relevant data for the search results</Item>
                <Item>Be able to filter results without losing context</Item>
                <Item>Quicker filters</Item>
              </List>

              <Title>Designing the solution</Title>
              <Paragraph>
                The starting point of the design was identifying layouts where
                the search results would be the main element, positioning any
                other contents around them:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="layouts"
                overflow
                alt="Layout exploration"
                description="Wireframes for different layout ideas (hand-drawn)"
              />

              <Paragraph>
                Following the natural page flow on TED, where the access to the
                searcher is preceded by a mandatory initial scope selection (of
                country and type of business opportunity, with publication being
                defaulted to all active notices), the proposed design breaks
                filtering into two blocks:
              </Paragraph>

              <List>
                <Item>
                  Scope refinement with values initially set (as if the user
                  came from the page flow described) and visually identified
                  with the primary blue colour from the palette
                </Item>
                <Item>
                  Search refinement with values unset (further filters the user
                  can apply) and visually identified with the secondary green
                  colour from the palette
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="sketch"
                overflow
                alt="Proposed layout"
                description="Sketch of the selected layout"
              />

              <Paragraph>
                As for the modern look-and-feel, a number of search applications
                and prototypes were sourced from design-sharing platforms and
                reviewed:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="inspiration"
                overflow
                alt="Inspiration"
                description="Artboard of search designs, for inspiration"
              />

              <Paragraph>
                The official{' '}
                <ExternalLink href="https://ted.europa.eu/api/swagger-ui.html#/search-controller-v-2">
                  TED website API
                </ExternalLink>{' '}
                helped identify which search fields were available and what
                types of controls should be developed:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="controls"
                overflow
                alt="Type of controls"
                description="Inventory of the search fields and types of controls"
              />

              <Paragraph>
                Because the main content has to be the search results, the
                available screen space for the filters is limited. But selectors
                needed a generous amount of space, because:
              </Paragraph>

              <List>
                <Item>
                  While some filters (like Country) do have relatively short
                  text values, most of them have rather long text values
                </Item>
                <Item>
                  The number of options range from a couple of values to tens of
                  values
                </Item>
              </List>

              <Paragraph>
                The solution implemented uses a visually distinct drawer
                surface, covering half of the screen:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="selection"
                overflow
                alt="Selection drawer"
                description="Prototype of the multiple selection component flow (Figma)"
              />

              <Paragraph>
                As on the sketched layout, the prototype initially implemented a
                table with the search results but that quickly proved
                problematic given the inconsistency of long text lengths and the
                amount of columns needed for the relevant fields. While it
                looked good on a larger screen, it wasn’t usable on a laptop or
                smaller devices.
              </Paragraph>

              <Paragraph>
                Instead, a result card has been implemented together with
                repositioning the filters as the screen gets reduced:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="card"
                overflow
                alt="Search result card"
                description="Prototype of the result card (Figma)"
              />

              <Title>Testing with a live prototype</Title>
              <Paragraph>
                Quickly built with React and MUI, the design is testable on
                desktop, tablet and mobile devices:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="screenshots-1"
                overflow
                alt="First batch of screenshots"
              />
              <Image
                edges={data.designs.edges}
                name="screenshots-2"
                overflow
                alt="Second batch of screenshots"
              />

              <Paragraph>Side-by-side shots showcasing both designs:</Paragraph>

              <Image
                edges={data.designs.edges}
                name="before"
                overflow
                alt="Current searcher"
                description="Current searcher at ted.europa.eu"
              />
              <Image
                edges={data.designs.edges}
                name="after"
                overflow
                alt="Finished prototype"
                description="Final searcher prototype implemented"
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
